@mixin modalLayout($size) {
    width: 100%;  // fill available space
    height: auto;  // hug to fit content
    min-height: 240px; // default min-height for most sizes

    @if $size == 'small' {
        max-width: 480px;  // ensure width doesn't exceed 480px
    }
    @else if $size == 'medium' {
        max-width: 640px;
    }
    @else if $size == 'large' {
        max-width: 880px;
    }
    @else if $size == 'sheet' {
        max-width: 975px;
        min-height: 540px; // override min-height for 'sheet'
    }

    // check for the full-height modifier class
    &.full-height {
        height: 100%;
    }
}

@mixin modal() {
	/**
	 * Local variable used for .tutorial-modal for
	 * building four arrow directions.
	 */
	$tutorial-modal-arrow-direction:
		'north',
		'east',
		'west',
		'south';

	.p-dialog {
		&.modal {
			// apply the modal size layout dynamically
            &.small-modal {
                @include modalLayout('small');
            }
            &.medium-modal {
                @include modalLayout('medium');
            }
            &.large-modal {
                @include modalLayout('large');
            }
            &.sheet-modal {
                @include modalLayout('sheet');
            }

			.p-dialog-header {
				border-bottom: $stamps-modal-header-border-bottom;
				background-color: $stamps-modal-header-background-color;
				color: $stamps-modal-header-text-color;
				padding: $stamps-modal-header-padding;

				.p-dialog-title {
					font-size: $stamps-modal-header-text-font-size;
					font-weight: $stamps-modal-header-font-weight;
				}

				.p-dialog-header-icons {
					.p-dialog-titlebar-icon,
					.p-dialog-header-icon {
						color: $stamps-modal-header-icon-color;

						&:hover {
							color: $stamps-modal-header-icon-hover-color;
						}
					}
				}
			}

			.p-dialog-content {
				background-color: $stamps-modal-body-background-color;
				color: $stamps-modal-body-text-color;
				padding: $stamps-modal-body-padding;
			}

			.p-dialog-footer {
				display: flex;
				flex-direction: row-reverse;
				border: $stamps-modal-footer-border;
				border-top: $stamps-modal-footer-border-top;
				background-color: $stamps-modal-footer-background-color;
				color: $stamps-modal-footer-text-color;
				padding: $stamps-modal-footer-padding;
				text-align: left;

				button {
					min-width: 80px;
				}
			}
		}

		&.error-modal,
		&.message-modal {
			.error-modal-body,
			.message-modal-body {
				min-height: 26px;

				p {
					margin-block-start: 0;
					margin-block-end: 1em;
				}

				.common-ui-list {
					p {
						margin-block-end: 0 !important;
					}
				}	
			}

			.error-modal-body {
				background: background-image-url($stamps-modal-error-icon) no-repeat left top;
				background-size: 26px;
				padding-left: 26px + 15px;

				textarea.feedback-input {
					width: 100%;
					resize: none;
				}
			}

			.error-modal-body-description,
			.message-modal-body-description {
				line-height: normal;
			}
		}

		&.warning-modal {
			.body {
				background: background-image-url($stamps-modal-warning-icon) no-repeat left;
				background-size: 37px;
				padding-left: 37px + 15px;
				background-position: left top;
				min-height: 36px;
				display: flex;
				align-items: center;
			}
		}

		/**
		 * Dev note:
		 * Unlike other modals, TutorialModal is not rendering Dialog header and footer.
		 * The tutorial content, i.e. the title, message and the button(s), is rendered
		 * in Dialog body. This is because it's easier to calculate the arrow position
		 * without having to worry about the height of the header or the footer.
		 */
		&.tutorial-modal {
			max-width: $stamps-tutorial-modal-max-width;
			width: $stamps-tutorial-modal-width;
			height: $stamps-tutorial-modal-height;
			min-height: $stamps-tutorial-modal-min-height;

			.p-dialog-content {
				padding: 0;
				display: flex;
				flex-direction: column;
				overflow: visible;
				border-bottom: $stamps-modal-body-border;


				.tutorial-modal-footer {
					display: flex;
					flex-direction: row-reverse;
					gap: $stamps-tutorial-modal-footer-gap;
					padding: $stamps-modal-footer-padding;
					flex: none;
					border-top: $stamps-modal-footer-border-top;

					.common-ui-button {
						.p-button-label {
							line-height: 20px; // 20px plus 4px padding makes footer height 60px
						}
					}
				}

				.tutorial-modal-title {
					display: flex;
					align-items: center;
					border-bottom: $stamps-modal-header-border-bottom;
					background-color: $stamps-modal-header-background-color;
					padding: $stamps-modal-header-padding;

					h1 {
						flex: 1;
						font-size: $stamps-modal-header-text-font-size;
						font-weight: $stamps-modal-header-font-weight;
						text-overflow: ellipsis;
						overflow: hidden;
						white-space: nowrap;
					}

					.common-ui-button {
						.pi {
							color: $stamps-icon-button-text-color;
							font-size: $stamps-modal-header-text-font-size;
						}

						.p-button-label {
							line-height: 22px; // 22px plus border width equals to h1 height
							padding: 0;
						}

						&:hover {
							.pi {
								color: darken($stamps-icon-button-text-color, 50%);
							}
						}
					}
				}

				.tutorial-modal-body {
					padding: $stamps-modal-body-padding;
					flex: 1 1 auto;
					overflow-y: auto;
				}

				// build four arrow directions
				@each $dir in $tutorial-modal-arrow-direction {
					@include tutorial-modal-arrow($dir, 12px, $stamps-modal-body-background-color, #ccc);
				}
			}
		}
	}
}
